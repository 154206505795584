<template>
  <div class="editor-markdown">
    <v-md-editor
      ref="editor"
      height="100%"
      v-model="content.value"
      :mode="content.mode"
      :autofocus="true"
      :default-show-toc="true"
      :include-level="[1, 2, 3, 4, 5, 6]"
      :toolbar="toolbar"
      :toolbar-config="toolbarConfig"
      :disabled-menus="[]"
      @upload-image="handleUploadImage"
      @save="submitSave"
      right-toolbar="preview toc sync-scroll fullscreen"
      left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link image code | fontFormat tip todo-list | save leave"
    ></v-md-editor>
  </div>
</template>

<script>
import { imageUpload } from '@/api/ResourceApi'

export default {
  name: 'MarkdownEditor',
  data() {
    const that = this
    return {
      content: {
        value: '',
        catalog: '',
        mode: 'editable' // edit(纯编辑模式) editable(编辑与预览模式) preview(纯预览模式)
      },
      toolbar: {
        leave: {
          title: '离开',
          icon: 'v-md-icon-open-in-new',
          action() {
            that
              .$confirm('是否放弃内容,离开当前页面！', '提示', {
                confirmButtonText: '继续编辑',
                cancelButtonText: '放弃离开',
                type: 'warning'
              })
              .catch(() => {
                that.$router.go(-1)
              })
          }
        },
        fontFormat: {
          title: '字体颜色大小',
          icon: 'v-md-icon-toc',
          menus: [
            {
              name: '正红色',
              text: '正红色',
              action(editor) {
                that.setFontFormat('#FF0000', editor)
              }
            },
            {
              name: '玫瑰色',
              text: '玫瑰色',
              action(editor) {
                that.setFontFormat('#FF00FF', editor)
              }
            },
            {
              name: '黄绿色',
              text: '黄绿色',
              action(editor) {
                that.setFontFormat('#00FF00', editor)
              }
            },
            {
              name: '闪蓝色',
              text: '闪蓝色',
              action(editor) {
                that.setFontFormat('#1E90FF', editor)
              }
            }
          ]
        }
      }
    }
  },
  methods: {
    // 设置字体颜色大小
    setFontFormat(colorCode, editor) {
      editor.insert(function (selected) {
        const prefix = "<font color='" + colorCode + "' size='4'>"
        const suffix = '</font>'
        const placeholder = '请输入内容...'
        const content = selected || placeholder
        return {
          text: `${prefix}${content}${suffix}`,
          selected: content
        }
      })
    },
    toolbarConfig: {
      'image-link': {
        insertWithSize: true
      }
    },
    // 上传图片
    handleUploadImage(event, insertImage, files) {
      // 上传服务器
      const formData = new FormData()
      formData.append('sourceType', 'CONTENT')
      formData.append('image', files[0])
      imageUpload(formData).then((res) => {
        if (res.code === 200) {
          const data = res.data
          insertImage({
            url: data.imageUrl + '?serial=[' + data.serial + ']',
            desc: '请输入图片描述',
            width: 'auto',
            height: 'auto'
          })
        }
      })
    },
    // 提交保存
    submitSave() {
      this.$emit('submitSave')
    },
    // 填充内容
    fillContent(content) {
      this.content.value = content
    },
    // 获取内容
    getContent() {
      this.content.catalog = this.$refs.editor.titles
      return this.content
    }
  }
}
</script>

<style scoped>
.editor-markdown {
  height: 100%;
  width: 100%;
}

/*允许选中复制*/
.editor-markdown >>> * {
  -webkit-user-select: text;
  -ms-user-select: text;
  -moz-user-select: text;
  user-select: text;
}

/*todo样式*/
.editor-markdown >>> .vuepress-markdown-body > ul.v-md-editor__todo-list {
  padding-left: 2.4em;
}

/*todo样式*/
.editor-markdown >>> .vuepress-markdown-body > ul.v-md-editor__todo-list li {
  list-style: none;
}

/*无序列表样式*/
.editor-markdown >>> .vuepress-markdown-body ul li {
  list-style: disc;
}

/*有序列表样式*/
.editor-markdown >>> .vuepress-markdown-body ol li {
  list-style: decimal;
}

/*h2下划线*/
.editor-markdown >>> .vuepress-markdown-body h2 {
  border-bottom: 0;
}
</style>
